import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    Select,
    Badge,
    Tooltip,
    Image,
    Textarea,
    Button,
    Flex
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import InfoIcon from "../../assets/svg/ic_info.svg";

import style from './input.module.scss'
import { useEffect, useRef, useState } from "react";
import { onKeyPress } from "common/validate";
export default function InputField({ label, type, mb, InputRightElement, children, errorText, info, ...rest }) {
    return <FormControl
        isInvalid={errorText?.length}
        mb={mb ? mb : '20px'}
    >
        <Flex style={{color: 'black'}}>
        {label ? <FormText label={label} /> : null}
        <Tooltip label={info} fontSize="sm" >
                  <Image src={InfoIcon} w={"17px"} mb={2} />
                </Tooltip>
                </Flex>
        {
            type === 'select' ?
                <Select
                    {...rest}
                >
                    {children}
                </Select>
                :
                type === 'textarea' ?
                    <FormControl
                        isInvalid={errorText?.length}
                    >
                        <Textarea
                            {...rest}
                        />
                        <FormErrorMessage>{errorText}</FormErrorMessage>
                    </FormControl>
                    :
                    <InputGroup>
                        <Input
                            type={type === 'integer' || type === 'decimal' ? 'text' : type}
                            onKeyPress={(e) => type === 'integer' ? onKeyPress(e) : type === 'decimal' ? onKeyPress(e, /^[0-9.]*$/) : {}}
                            {...rest}
                        />
                        {InputRightElement}
                    </InputGroup>
        }


        <FormErrorMessage>{errorText}</FormErrorMessage>
    </FormControl>
}

export const SelectMultiple = ({ option, errorText, value, placeholder, label, badgeColor, onChange }) => {
    const [selectList, setSelectList] = useState([])
    const [list, setList] = useState([])
    const [listFilter, setListFilter] = useState([])
    const [serach, setSearch] = useState('')
    const wrapperRef = useRef(null);
    const [clicked, setClicked] = useState(false);
    useEffect(() => {
        setSelectList(value)
    }, [value])
    useEffect(() => {
        if (option?.length) {
            setList(option)
            setListFilter(option)
        }
    }, [option])
    const handleClick = (items) => {
        let data = [
            ...selectList,
            items
        ]
        let listData = data?.length ? listFilter.filter(item => item.label !== items.label) : list
        setListFilter(listData)
        onChange && onChange(data)
        setSelectList(data)
        onClose()
        setSearch('')
    }
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                onClose();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);
    const onClose = () => {
        setClicked(false)
    }
    const handleSearch = (e) => {
        const { value } = e.target
        setSearch(value)
    }
    const filterArray = (inputValue = '') => {
        return inputValue?.length ? listFilter?.filter(obj => obj.label.toLowerCase().includes(inputValue.toLowerCase())) : listFilter;
    };
    const handleFilter = (list) => {
        const data = selectList?.filter((item, key) => item.value !== list.value)
        const dataList = [
            ...listFilter,
            list
        ]
        setSelectList(data)
        onChange && onChange(data)
        setListFilter(dataList)
    }
    return <div className={style.multiselect} ref={wrapperRef}>
        {label ? <FormText label={label} /> : null}
        <div className={`${style.multiselectControl} ${errorText ? style.errorField : ''}`}>

            {
                selectList?.length ?
                    selectList?.map((items, key) => <Badge py='1.5' borderRadius={10} mr='1' colorScheme={badgeColor} key={key}>
                        {items.icon ? <img src={items.icon} /> : null}
                        {items.label}
                        <Button _hover={{ bg: "transparent" }} _focus={{ boxShadow: 'unset' }} _active={{ bg: "transparent" }} bg="transparent" pe={0} ps={1} height={'auto'} onClick={() => handleFilter(items)}><CloseIcon w={3} h={3} /></Button>
                    </Badge>) : null
            }
            <Input type="text" value={serach} placeholder={placeholder} onChange={handleSearch} onClick={() => setClicked(true)} />
        </div>
        {errorText ? <div className={style.errorText}>{errorText}</div> : null}
        {clicked ? <ul>
            {
                filterArray(serach)?.map((items, key) => {
                    return <li key={key} onClick={() => handleClick(items)}>{items.label}</li>
                })
            }
        </ul> : null}
    </div>
}


export const FormText = ({ label, info }) => {
    return <FormLabel display='flex' alignItems='center' fontSize='14px' fontWeight={"600"}>
        {label}
        {info ? <Tooltip hasArrow label={info} bg='gray.300' color='black'>
            <Image src={InfoIcon} w={"12px"} ml={1} />
        </Tooltip> : null}
    </FormLabel>
}