// Chakra imports
import {
  ArrowForwardIcon,
  CheckIcon,
  EmailIcon,
  PhoneIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import { FaPencilAlt } from "react-icons/fa";
import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import DeleteModal from "components/Modals/DeleteModal";
import Catalog from "components/Tables/Catalog";
import Fetch from "../../common/fetch";
import React, { useEffect, useState } from "react";
import { FaDiceThree, FaFileContract } from "react-icons/fa";
import FilterIcon from "../../assets/svg/filter-icon.svg";
import { ActionsIco } from "components/Icons/menu";
import { PlusIco } from "components/Icons/menu";
import { useHistory, withRouter } from "react-router-dom";
import { FiltersIco } from "components/Icons/menu";
import { SearchIco } from "components/Icons/Catalog";
import DataTable from "components/DataTable";

function CatalogListing(props) {
  const history = useHistory()
  const color = useColorModeValue("blue.500", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const borderColorTD = useColorModeValue("gray.900", "white");
  const [catalogueData, setCatalogueData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const toast = useToast();
  const getCatlogueData = () => {
    Fetch("dashboard/product-object/").then((res) => {
      if(res.status){
        setCatalogueData(res?.data?.results?.map(items =>{return {...items,actions:items}}))
      }
    });
  };
  const processEmbed = () => {
    setisLoading(true);
    Fetch("dashboard/process-embeddings", null, { method: "post" }).then(
      (res) => {
        if (res.status) {
          setisLoading(false);
          toast({
            title: `Process Embeddings.`,
            description: `Process embeddings is completed.`,
            status: "success",
            duration: 3000,
            isClosable: false,
          });
        } else {
          setisLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    getCatlogueData();
  }, []);
  const handleDeleteRequest = (id) => {
    Fetch(`dashboard/product-object/${id}/`, null, { method: "delete" }).then(
      (res) => {
        if (res.status) {
          getCatlogueData();
          toast({
            title: "Catalogue Deleted.",
            description: "We've deleted your catalogue successfully.",
            status: "success",
            duration: 3000,
            isClosable: false,
          });
        }
      }
    );
  };
  const columns = [
    { accessor: "id", Header: "DATA ID", width: 130 },
    { accessor: "object_name", Header: "Name", width: 130 },
    { accessor: "template", Header: "Template", width: 130 },
    { accessor: "attributes", Header: "Attributes", width: 130,
    Cell: (cellValues) => <Flex justifyContent='center' alignItems='center' borderWidth='1px' borderColor={borderColorTD} color={borderColorTD} borderRadius='25px' width={18} fontSize={'12px'} fontWeight={500} height={18}>0</Flex> },
    {
      accessor: "categories", Header: "Categories", width: 160,
      Cell: (cellValues) => {
        console.log(cellValues);
        return cellValues.value.map((items, key) => <Badge py='1.5' borderRadius={10} mr='1' mb='1' colorScheme={'yellow'} key={key}>
          {items.name}
        </Badge>)
      }
    },
    {
      accessor: "created_by", Header: "Documents", width: 130,
      Cell: (cellValues) => <Flex justifyContent='center' alignItems='center' borderWidth='1px' borderColor={borderColorTD} color={borderColorTD} borderRadius='25px' width={18} fontSize={'12px'} fontWeight={500} height={18}>0</Flex>
    },
    { accessor: "media_count", Header: "Media", width: 130,
    Cell: (cellValues) => <Flex justifyContent='center' alignItems='center' borderWidth='1px' borderColor={borderColorTD} color={borderColorTD} borderRadius='25px' width={18} fontSize={'12px'} fontWeight={500} height={18}>0</Flex> },
    { accessor: "user_exp", Header: "User Experiences", width: 130 },
    {
      accessor: "actions",
      Header: "Actions",
      width: 130,
      Cell: (cellValues) => {
        return (
          <Flex>
          <DeleteModal
            name={"Delete"}
            title={"Are you sure you want to delete this catalog?"}
            handleDelete={()=>handleDeleteRequest(cellValues.value.id)}
          />
          <Button
            variant="outline"
            color={color}
            borderColor={color}
            colorScheme="blue"
            onClick={() => handleUpdateRequest(cellValues.value.id)}
          >
            <FaPencilAlt color={color} />
          </Button>
        </Flex>
        );
      },
    },
  ];
  const handleUpdateRequest = (id) => {
    history.push({
      pathname: `/admin/catalog/${id}`,
    });
  };
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Flex p="6px 0px 22px 0px" justify={"space-between"}>
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          Catalog
        </Text>
        <Stack direction={"row"} spacing={4}>
          <InputGroup w={"200px"}>
            <InputRightElement pointerEvents="none">
              {/* <SearchIcon color="gray.300" /> */}
              <SearchIco />
            </InputRightElement>
            <Input
              type="text"
              placeholder="Search"
              textColor={"rgba(177, 177, 177, 1)"}
              fontWeight={400}
              fontSize={"14px"}
              lineHeight={"24px"}
            />
          </InputGroup>
          <Button
            w={"150px"}
            colorScheme="gray"
            color={"gray"}
            fontWeight={"light"}
            variant="outline"
          >
            <Flex justify={"space-between"} minW={"100%"}>
              <Text
                fontSize={"14px"}
                lineHeight={"24px"}
                textColor={"rgba(0, 0, 0, 1)"}
              >
                Filters
              </Text>
              <FiltersIco />
            </Flex>
          </Button>

          <Button
          
            leftIcon={<PlusIco />}
            onClick={() => props.history.push("/admin/catalog/add")}
            variant="outline"
            width={"120px"}
            h={"40px"}
            borderRadius={"8px"}
            border={"1px solid #0584FE"}
            p={"8px 14px 8px 14px"}
            gap={"10px"}
            fontWeight={"600"}
            fontSize={"14px"}
            lineHeight={"24px"}
            letterSpacing={"1%"}
            textAlign={"center"}
            pl={"15px"}
            textColor={"rgba(5, 132, 254, 1)"}
            borderColor={"rgba(5, 132, 254, 1)"}
          >
            NEW ITEM
          </Button>
          <Button
            // leftIcon={<PlusIco />}
            isLoading={isLoading}
            onClick={processEmbed}
            variant="outline"
            width={"120px"}
            h={"40px"}
            borderRadius={"8px"}
            border={"1px solid #0584FE"}
            p={"8px 14px 8px 14px"}
            gap={"10px"}
            fontWeight={"600"}
            fontSize={"14px"}
            lineHeight={"24px"}
            letterSpacing={"1%"}
            textAlign={"center"}
            textColor={"rgba(5, 132, 254, 1)"}
            borderColor={"rgba(5, 132, 254, 1)"}
            pl={"15px"}
          >
            Embeddings
          </Button>
          {/* <Button
            rightIcon={<ActionsIco />}
            colorScheme="#0584FE"
            variant="outline"
            width={"120px"}
            height={"40px"}
            borderRadius={"8px"}
            border={"1px solid #0584FE"}
            padding={"8px 14px 8px 14px"}
            gap={"10px"}
            fontWeight={700}
            fontSize={"14px"}
            lineHeight={"24px"}
            letterSpacing={"1%"}
            textAlign={"center"}
            textColor={"#0584FE"}
          >
            ACTIONS
          </Button> */}
        </Stack>
      </Flex>
      <CardBody
        border={"1px solid lightgrey"}
        padding={"15px"}
        borderRadius="5px"
        marginBottom="20px"
        p={0}
      >
        <DataTable table={{ columns: columns, rows: catalogueData }} path="/inbound/asn/create" />
      </CardBody>
    </Flex>
  );
}

export default withRouter(CatalogListing);
