// Chakra imports
import {
  Portal,
  useDisclosure,
  Stack,
  Box,
  useColorMode,
} from "@chakra-ui/react";
import Footer from "components/Footer/Footer.js";
import {
  ArgonLogoDark,
  ArgonLogoLight,
  ChakraLogoDark,
  ChakraLogoLight,
} from "components/Icons/Icons";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";
import CatalogListing from "views/Dashboard/CatalogListing";

import NewCatalog from "views/Dashboard/NewCatalog";
// Custom Chakra theme
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
// Custom components
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import CategoriesListing from "views/Dashboard/CategoriesListing";
import Settings from "views/setting";

import NewCategory from "views/Dashboard/NewCategory";
import Template from "views/template";

import NewTemplate from "views/template/addEdit";

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [fixed, setFixed] = useState(false);
  const { colorMode } = useColorMode();
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    // let activeRoute = "Default Brand Text";
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin" && prop.component) {
        console.log(prop.layout + prop.path);
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  // Chakra Color Mode
  return (
    <Box>
      <Box minH="40vh" w="100%" position="absolute" bgSize="cover" />

      <Sidebar
        routes={routes}
        // logo={
        //   <Stack direction="row" spacing="12px" align="center" justify="center">
        //     {colorMode === "dark" ? (
        //       <ArgonLogoLight w="74px" h="27px" />
        //     ) : (
        //       <ArgonLogoDark w="74px" h="27px" />
        //     )}
        //     <Box
        //       w="1px"
        //       h="20px"
        //       bg={colorMode === "dark" ? "white" : "gray.700"}
        //     />
        //     {colorMode === "dark" ? (
        //       <ChakraLogoLight w="82px" h="21px" />
        //     ) : (
        //       <ChakraLogoDark w="82px" h="21px" />
        //     )}
        //   </Stack>
        // } // app logo here
        display="none"
        {...rest}
      />

      <MainPanel
        w={{
          base: "100%",
          xl: "calc(100% - 275px)",
        }}
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            brandText={getActiveRoute(routes)}
            secondary={getActiveNavbar(routes)}
            fixed={fixed}
            {...rest}
          />
        </Portal>
        {getRoute() ? (
          <PanelContent>
            <PanelContainer>
              <Switch>
                {/* {getRoutes(routes)} */}
                <Route path={"/admin/catalog/:id"} component={NewCatalog} />
                <Route path={"/admin/catalog"} component={CatalogListing} />
                <Route path={"/admin/categories/:id"} component={NewCategory} />
                <Route path={"/admin/template/:id"} component={NewTemplate} />
                <Route
                  path={"/admin/categories"}
                  component={CategoriesListing}
                />
                <Route
                  path={"/admin/template"}
                  component={Template}
                />
                      <Route
                  path={"/admin/setting"}
                  component={Settings}
                />
                {/* <Redirect from="/admin" to="/admin/add-catalog" /> */}
              </Switch>
            </PanelContainer>
          </PanelContent>
        ) : null}
        <Footer />
        <Portal>
          <FixedPlugin
            secondary={getActiveNavbar(routes)}
            fixed={fixed}
            onOpen={onOpen}
          />
        </Portal>
      </MainPanel>
    </Box>
  );
}
